import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';

const withGuest = (WrappedComponent, redirectTo = '/') => {
    return (props) => {
        const { isAuthenticated, loading } = useSelector(state => state.auth);
        
        if (loading) return <LoadingScreen />;

        return isAuthenticated ? <Navigate to={redirectTo} /> : <WrappedComponent {...props} />;
    };
};

export default withGuest;
