import Header from "../components/Header";
import '../assets/css/styles.css';
import Hero from "../components/Hero";
import ConnectTradeStation from "../components/ConnectTradeStation";
import Contactus from "../components/Contactus";
import FooterContents from "../components/FooterContents";
import tele_icon from "../assets/img/telegram_icon.png";
import Pricing from "../components/Pricing";
import Carousel from "../components/Carousel";


function Home() {
  return (
    <div className="main-area relative">
      <div className="tele_icon">
        <img src={tele_icon} alt="" className="fixed right-3 bottom-3 z-50" />
      </div>
      <Header/>  
      <Hero/>
      <ConnectTradeStation/>
      <Carousel/>
      <Pricing/>
      <Contactus/>
      <FooterContents/>
    </div>
  );
}

export default Home;
