import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axiosInstance from "../utils/axios";
import { toast } from 'react-toastify';

const ResetPassword = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const validatePasswords = () => {
        return password !== '' && password === confirmPassword;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validatePasswords()) {
            setError('Passwords do not match or are empty.');
            return;
        }
        setError('');
        try {
            const response = await axiosInstance.post(`/reset-password/${token}`, { password });
            toast.success(response.data.message);
        } catch (error) {
            toast.error('Error resetting password.');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
            <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg">
                <h2 className="text-xl font-semibold text-center mb-4">Reset Your Password</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="flex flex-col">
                        <label htmlFor="password" className="text-sm font-medium text-gray-700 mb-2">
                            New Password
                        </label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                            placeholder="Enter your new password"
                            required
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="confirmPassword" className="text-sm font-medium text-gray-700 mb-2">
                            Confirm Password
                        </label>
                        <input
                            type="password"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                            placeholder="Confirm your new password"
                            required
                        />
                        {confirmPassword && password !== confirmPassword && (
                            <span className="text-red-500 text-sm mt-1">Passwords do not match!</span>
                        )}
                    </div>
                    <button
                        type="submit"
                        className={`w-full py-2 bg-blue-500 text-white rounded-md transition hover:bg-blue-600 focus:ring-2 focus:ring-blue-300 ${!validatePasswords() ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                        disabled={!validatePasswords()}
                    >
                        Reset Password
                    </button>
                </form>
                {error && <p className="mt-4 text-center text-red-600">{error}</p>}
                <div className="mt-4 text-center">
                    <Link to="/login" className="text-blue-500 hover:underline">
                        Back to Login
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
