import React, { useState, useEffect } from 'react';
import main_logo from '../assets/img/logo.png';
import { TfiAlignJustify } from "react-icons/tfi";
import { TfiClose } from "react-icons/tfi";
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../store/authSlice';

export default function Header() {
    const location = useLocation();
    const dispatch = useDispatch();
    const { isAuthenticated, loading, user } = useSelector(state => state.auth)
    const isSignupPath = location.pathname.includes('/signup');
    const isLoginPath = location.pathname.includes('/login');
    

    const [bgColor, setBgColor] = useState('bg-transparent');

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setBgColor('bg-white shadow-md');
        } else {
            setBgColor('bg-transparent');
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const [isToggled, setIsToggled] = useState(false);


    const showmenu = () => {
        setIsToggled(!isToggled);
    }

    const handleSignOut = () => {
        dispatch(logout());
    }

    return (

        <>
            <header className={`${bgColor} py-6 px-6 md:px-6 lg:px-0 sticky top-0 z-50 ${isToggled ? 'bg-color' : ''}`}>
                <section id="navbar" className='header-container '>
                    <div className="header-wrapper ">

                        <nav className={`flex justify-between items-start  lg:items-center lg:px-12 flex-col lg:flex-row  ${isToggled ? 'menu-background' : ''}`}>
                            <Link to="/"><img src={main_logo} alt="Logo" className="header-logo max-w-full h-auto sm:h-[42px] md:h-[42px] lg:h-[79px]" /></Link>
                            { !isAuthenticated &&
                                <div className='flex flex-col lg:flex-row gap-4 flex-1 lg:flex-none justify-around'>
                                    {
                                        !isLoginPath && 
                                        <Link className={`bg-[#546d87]  py-2 px-4 text-base text-white font-semibold hidden my-auto lg:block ${isToggled ? 'show-menu' : ''}`} to={'/login'}>
                                            Log In
                                        </Link>
                                    }
                                    {
                                        !isSignupPath && 
                                        <Link className={`bg-[#546d87]  py-2 px-4 text-base text-white font-semibold hidden my-auto lg:block ${isToggled ? 'show-menu' : ''}`} to={'/signup'}>
                                            Sign Up
                                        </Link>
                                    }
                                </div>
                            }
                            {
                                isAuthenticated &&
                                <div className='flex flex-col lg:flex-row gap-8 flex-1 lg:flex-none justify-around items-center'>
                                    <p className='text-base text-gray-700 font-semibold text-center'>{user}</p>
                                    <button className={`bg-[#546d87]  py-2 px-4 text-base text-white font-semibold my-auto`} disabled={loading} onClick={handleSignOut}>
                                        {
                                            loading ? 
                                                <svg
                                                    className="animate-spin h-5 w-5 text-white mx-auto"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8v0a8 8 0 018 8h-2a6 6 0 10-12 0v0H4z"
                                                    ></path>
                                                </svg>
                                                : "Sign Out"
                                        }
                                    </button>
                                </div>
                            }
                        </nav>
                    </div>

                    {isToggled ? (
                        <TfiClose className="absolute top-8 right-7 text-[1.25rem] text-black font-extrabold block lg:hidden cursor-pointer" onClick={showmenu} />
                    ) : (
                        <TfiAlignJustify className="absolute top-8 right-7 text-[1.25rem] text-black font-extrabold block lg:hidden cursor-pointer" onClick={showmenu} />
                    )}


                </section>
            </header>


        </>


    )
}
