import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function Cancelled() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
        <h1 className="text-3xl font-bold text-gray-800 mb-4 text-center">Payment Cancelled</h1>
        <p className="text-gray-600 mb-6">
          It seems like your payment process was cancelled. But no worries, you can try again anytime.
        </p>
        <div className="flex justify-around">
          <a href="/" className="text-blue-500 hover:text-blue-700 transition-all">
            Go Back to Home
          </a>
          <button onClick={handleGoBack} className="text-blue-500 hover:text-blue-700 transition-all">
            Try Again
          </button>
        </div>
      </div>
    </div>
  )
}
