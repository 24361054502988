import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from "../utils/axios";
import { toast } from 'react-toastify';

const SuccessPage = () => {

  useEffect(() => {
    const createStripeConnectedAccount = async () => {
      const email = localStorage.getItem('email');
      try {
        const response = await axiosInstance.post('/create-stripe-connected-account', {
          email
        });
        window.location.href = response.data.url;
      }
      catch(error) {
        if (error.response) {
          toast.error(error.response.data.msg);
        } else {
          console.error('Error:', error.message);
        }
      }
    }
    createStripeConnectedAccount();
  }, [])

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full text-center">
        <h1 className="text-4xl font-bold text-green-600 mb-4">Payment Successful!</h1>
        <p className="text-gray-700 mb-6">
          Thank you for your purchase. Your transaction has been completed successfully.
        </p>
        <div className="flex justify-center space-x-4 flex-col">
          <svg
            className="animate-spin h-5 w-5 text-blue-500 mx-auto"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v0a8 8 0 018 8h-2a6 6 0 10-12 0v0H4z"
            ></path>
          </svg>
          <p className='text-gray-600 mt-4'>We are creating stripe connect account. Please wait while redirecting to verification link.</p>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
