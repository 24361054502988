import Header from "../components/Header";
import '../assets/css/styles.css';
import FooterContents from "../components/FooterContents";
import tele_icon from "../assets/img/telegram_icon.png";
import { useEffect, useState } from "react";
import axiosInstance from "../utils/axios";
import { Link, useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);


function SignUp() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const referralCode = params.get('ref');
  const [referralUser, setReferralUser] = useState(null);

  const agreements = [
    {
      id: 1,
      name: 'Terms of Service',
      url: '/assets/compliances/Agreement - TERMS OF SERVICE.pdf'
    },
    {
      id: 2,
      name: 'Risk Disclosure',
      url: '/assets/compliances/Agreement - RISK DISCLOSURE AGREEMENT.pdf'
    },
    {
      id: 3,
      name: 'Privacy Policy',
      url: '/assets/compliances/Agreement - PRIVACY POLICY.pdf'
    },
    {
      id: 4,
      name: 'User Agreement',
      url: '/assets/compliances/Agreement - CLIENT AGREEMENT.pdf'
    }
  ];

  const [formData, setFormData] = useState({
    name: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    membership: 'signal',
    agreements: [],
    promotion_code: ''
  });

  const [errors, setErrors] = useState({});
  const [waiting, setWaiting] = useState(false);
  const [applyingPromotionCode, setApplyingPromotionCode] = useState(false);
  const [discount, setDiscount] = useState({});

  const handleChange = (e) => {
      setFormData({
          ...formData,
          [e.target.name]: e.target.value,
      });
      setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleAgreementChange = (agreement) => {
      setFormData((prevData) => ({
          ...prevData,
          agreements: prevData.agreements.includes(agreement)
              ? prevData.agreements.filter((a) => a !== agreement)
              : [...prevData.agreements, agreement],
      }));
  };

  const applyPromotionCode = async (e) => {
    e.preventDefault();
    try {
      setApplyingPromotionCode(true);
      const response = await axiosInstance.post('/calculate-discount', {
          promotion_code: formData.promotion_code,
          membership: formData.membership
        }, {
          headers: {
            'Content-Type': 'application/json',
          }
      });
      const res = response.data;
      setDiscount(res);
    }
    catch(error) {
      setDiscount({});
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        console.error('Error:', error.message);
      }
    }
    finally {
      setApplyingPromotionCode(false);
    }
  }

  const validateForm = () => {
    const tempErrors = {};

    if (!formData.name.trim()) tempErrors.name = "Name is required.";
    if (formData.username.length < 5) tempErrors.username = "Username must be at least 5 characters.";
    if (!/\S+@\S+\.\S+/.test(formData.email)) tempErrors.email = "Email address is invalid.";
    if (formData.password.length < 6) tempErrors.password = "Password must be at least 6 characters.";
    if (formData.confirmPassword !== formData.password) tempErrors.confirmPassword = "Passwords do not match.";
    if (!/^\+?[1-9]\d{1,14}$/.test(formData.phone)) tempErrors.phone = "Please enter a valid phone number.";
    if (formData.agreements.length !== agreements.length) tempErrors.agreements = "You must agree to all terms.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      if (!validateForm()) return;
      // Handle form submission and Stripe payment here
      try {
        setWaiting(true);
        const response = await axiosInstance.post('/signup', {
            ...formData,
            agreement: agreements.length == 4,
            referral: referralCode
          }, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        setWaiting(false);
        const res = response.data;
        localStorage.setItem("email", formData.email);
        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({
          sessionId: res.sessionId
        });
        if (result.error) {
          toast.error(result.error.message);
        }
      }
      catch(error) {
        setWaiting(false);
        if (error.response) {
          toast.error(error.response.data.msg);
        } else {
          console.error('Error:', error.message);
        }
      }
  };

  const hasErrors = () => {
    for (const key in errors)
      if(errors[key] != "")
        return true;
    return false;
  }

  useEffect(() => {
    if(!referralCode)
      return;
    const fetchReferralUser = async () => {
      try {
        const response = await axiosInstance.post('/user-by-referralcode', {
          referral_code: referralCode
        });
        toast.success(`You were referred by ${response.data.name != '' ? response.data.name : response.data.username}`)
        setReferralUser(response.data);
      }
      catch(error) {
        if (error.response) {
          toast.error(error.response.data.msg);
        } else {
          console.error('Error:', error.message);
        }
      }
    }

    fetchReferralUser();
  }, [referralCode])

  return (
    <>
      <div className="main-area relative">
        <div className="tele_icon"><img src={tele_icon} alt="" className="fixed right-3 bottom-3 z-50" /></div>
        <Header />
        <div className="py-16 flex items-center justify-center bg-gray-100">
          <div className="bg-white p-8 rounded shadow-md w-full max-w-lg">
            <h2 className="text-2xl font-bold mb-6 text-center">Sign Up</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className={`w-full px-3 py-2 border rounded ${errors.name ? 'border-red-500' : ''}`}
                  required
                />
                {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="username">Username</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  className={`w-full px-3 py-2 border rounded ${errors.username ? 'border-red-500' : ''}`}
                  required
                />
                {errors.username && <p className="text-red-500 text-sm">{errors.username}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`w-full px-3 py-2 border rounded ${errors.email ? 'border-red-500' : ''}`}
                  required
                />
                {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className={`w-full px-3 py-2 border rounded ${errors.password ? 'border-red-500' : ''}`}
                  required
                />
                {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="confirmPassword">Confirm Password</label>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className={`w-full px-3 py-2 border rounded ${errors.confirmPassword ? 'border-red-500' : ''}`}
                  required
                />
                {errors.confirmPassword && <p className="text-red-500 text-sm">{errors.confirmPassword}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="phone">Phone</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className={`w-full px-3 py-2 border rounded ${errors.phone ? 'border-red-500' : ''}`}
                  required
                />
                {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Membership Type</label>
                <select
                  name="membership"
                  value={formData.membership}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                >
                  <option value="signal">Signal Only - Monthly</option>
                  <option value="signal-annual">Signal Only - Annual</option>
                  <option value="auto">Auto Trader Enabled - Monthly</option>
                  <option value="auto-annual">Auto Trader Enabled - Annual</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="promotion_code">Promotional Code</label>
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    id="promotion_code"
                    name="promotion_code"
                    value={formData.promotion_code}
                    onChange={handleChange}
                    className={`flex-grow px-3 py-2 border rounded ${errors.promotion_code ? 'border-red-500' : 'border-gray-300'}`}
                  />
                  <button
                    onClick={applyPromotionCode}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                    disabled={formData.promotion_code == '' || applyingPromotionCode}
                  >
                    { applyingPromotionCode ? (
                      <svg
                        className="animate-spin h-5 w-5 text-white mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8v0a8 8 0 018 8h-2a6 6 0 10-12 0v0H4z"
                        ></path>
                      </svg>
                    ) : (
                      'Apply'
                    )}
                  </button>
                </div>
                { Object.keys(discount).length > 0 &&
                  <div className="bg-gray-100 p-4 rounded mt-4 text-gray-800">
                    <h3 className="font-bold text-lg mb-2">Discount Information</h3>
                    <ul className="space-y-1">
                      <li className="flex">
                        <span className="font-semibold w-1/2 text-left">Discount Details:</span>
                        <span className="w-1/2 text-left">
                          {discount.discount_type === "amount" && '$'}
                          {discount.discount_value}
                          {discount.discount_type === "percent" && '%'}
                        </span>
                      </li>
                      <li className="flex">
                        <span className="font-semibold w-1/2 text-left">Original Price:</span>
                        <span className="w-1/2 text-left">${discount.original_price}</span>
                      </li>
                      <li className="flex">
                        <span className="font-semibold w-1/2 text-left">Discount Amount:</span>
                        <span className="w-1/2 text-left">${discount.discount_amount}</span>
                      </li>
                      <li className="flex">
                        <span className="font-semibold w-1/2 text-left">Discounted Price:</span>
                        <span className="w-1/2 text-left">${discount.discounted_price}</span>
                      </li>
                    </ul>
                  </div>
                }
                {errors.promotion_code && <p className="text-red-500 text-sm">{errors.promotion_code}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Agreements</label>
                {agreements.map((agreement) => (
                  <div key={agreement.id}>
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        onChange={() => handleAgreementChange(agreement.name)}
                        checked={formData.agreements.includes(agreement.name)}
                        className="form-checkbox"
                      />
                      <Link to={agreement.url} target="_blank">
                        <span className="ml-2 text-blue-400 underline">{agreement.name}</span>
                      </Link>
                    </label>
                  </div>
                ))}
                {errors.agreements && <p className="text-red-500 text-sm">{errors.agreements}</p>}
                <p className="my-4">By checking each box, you acknowledge that you have read, understood, and agree to the terms and conditions outlined in each corresponding agreement.</p>
              </div>
              <div className="mt-4">
                <button
                  type="submit"
                  disabled={waiting}
                  className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                >
                  { waiting ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v0a8 8 0 018 8h-2a6 6 0 10-12 0v0H4z"
                      ></path>
                    </svg>
                  ) : (
                    'Sign Up'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
        <FooterContents />
      </div>
    </>
  );
}

export default SignUp;
