import React from 'react'
import axiosInstance from "../utils/axios";
import { toast } from 'react-toastify';

export default function StripeReAuth() {

  const createStripeConnectedAccount = async () => {
    const email = localStorage.getItem('email');
    try {
      const response = await axiosInstance.post('/create-stripe-connected-account', {
        email
      });
      window.location.href = response.data.url;
    }
    catch(error) {
      if (error.response) {
        toast.error(error.response.data.msg);
      } else {
        console.error('Error:', error.message);
      }
    }
  }

  const handleRetry = () => {
    createStripeConnectedAccount();
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
        <h1 className="text-3xl font-bold text-gray-800 mb-4 text-center">Session Expired</h1>
        <p className="text-gray-600 mb-6">
          Your session expired. Please try again.
        </p>
        <div className="flex justify-around">
          <a href="/" className="text-blue-500 hover:text-blue-700 transition-all">
            Go Back to Home
          </a>
          <button onClick={handleRetry} className="text-blue-500 hover:text-blue-700 transition-all">
            Try Again
          </button>
        </div>
      </div>
    </div>
  )
}
