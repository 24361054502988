import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../utils/axios';

export const checkAuth = createAsyncThunk('auth/checkAuth', async () => {
    const response = await axiosInstance.get('/check-auth', { withCredentials: true });
    return response.data;
});

export const logout = createAsyncThunk('auth/logout', async () => {
    await axiosInstance.post('/logout', {}, { withCredentials: true });
});

const initialState = {
  isAuthenticated: false,
  user: null,
  loading: true
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(checkAuth.pending, (state) => {
            state.loading = true;
        })
        .addCase(checkAuth.fulfilled, (state, action) => {
            state.isAuthenticated = action.payload.authenticated;
            state.user = action.payload.email;
            state.loading = false;
        })
        .addCase(checkAuth.rejected, (state) => {
            state.isAuthenticated = false;
            state.user = null;
            state.loading = false;
        })
        .addCase(logout.pending, (state) => {
            state.loading = true;
        })
        .addCase(logout.rejected, (state) => {
            state.loading = false;
        })
        .addCase(logout.fulfilled, (state) => {
            state.isAuthenticated = false;
            state.user = null;
            state.loading = false;
        });
  },
})
export default authSlice.reducer