import React from 'react';
import test_img from '../assets/img/finger-touch.jpeg';

export default function Contactus() {
  return (
    <>
      <section id="contact-us-form" className="contact-us-form">
        <div className="contact-us-container bg-white flex justify-center">
          <div className="contact-us-wrapper w-[90%] lg:w-[88%] flex flex-col lg:flex-row pb-24">
            <div className="contact-us-txt w-full lg:w-1/2 mb-8 md:mb-0 md:px-0 xl:px-10">
              <h3 className="text-[24px] leading-[32px] lg:text-[28px] lg:leading-[36px] xl:text-[32px] xl:leading-[40px] font-medium">Contact Us Today</h3>
              <p className='mt-4 text-[16px] leading-[24px] xl:text-[18px]'>Fill out the contact form to get in touch with our team. We're here to help you with any questions or concerns.</p>
            </div>
            <div className="contact-us-form w-full lg:w-1/2 ">
              <section className=" max-w-xl mx-auto">
                <form className="space-y-4">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="name" className="block text-gray-700 text-[14px] leading-[21px] xl:text-[16px] mb-1">
                        Name
                      </label>
                      <input
                        type="text" id="name" className="w-full px-4 py-2  bg-[#ededed]  focus:outline-none focus:ring-2 focus:ring-brinzal" />
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-gray-700  mb-1 text-[14px] leading-[21px] xl:text-[16px]">
                        Email
                      </label>
                      <input type="email" id="email" className="w-full px-4 py-2  bg-[#ededed]  focus:outline-none focus:ring-2 focus:ring-brinzal" />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="message" className="block text-gray-700 text-[14px] leading-[21px] xl:text-[16px] mb-1">
                      Message
                    </label>
                    <textarea id="message" rows="5" className="w-full px-4 py-2  bg-[#ededed] focus:outline-none focus:ring-2 focus:ring-brinzal"></textarea>
                  </div>

                  <div className="flex flex-col md:flex-row justify-start md:justify-between items-start md:items-center mt-4">
                    <button
                      type="submit"
                      className="bg-[#C8184C] text-white py-[0.5rem] px-[3rem] text-[16px] hover:bg-[#bc2c57] transition duration-200 mt-4 md:mt-0"
                    >
                      Submit
                    </button>

                    <span className="text-gray-700 mt-4 md:mt-0 pl-0 md:pl-10 text-xs text-left md:text-right">
                      This site is protected by reCAPTCHA and the Google <span className="text-[.8rem] font-bold">Privacy Policy</span> and <span className="text-[.8rem] font-bold">Terms of Service</span> apply.
                    </span>
                  </div>

                </form>
              </section>
            </div>
          </div>
        </div>


      </section>




      <section id="powering" className="">
        <div className="relative h-auto lg:h-[70vh] py-10 lg:pr-72">
     
          <div
            className="absolute inset-0 bg-cover bg-center  bg-white/30" 
            style={{
              backgroundImage: `url(${test_img})`,

            }}
          ></div>

         
          <div className="absolute inset-0 bg-white opacity-50"></div>

         
          <div className="relative flex flex-col justify-center h-full text-[#111827] px-4 sm:px-8 md:px-10 lg:pl-28 lg:pr-12">
            <h2 className="text-[30px] leading-[36px] md:text-[35px] md:leading-[42px] lg:text-[50px] py-10 text-left font-medium">
              Powering Smart Trading Decisions
            </h2>
            <p className="text-[16px] leading-[24px] lg:text-[17px] lg:leading-[30px] mt-0 max-w-full text-justify">
              Founded with the vision of revolutionizing the trading experience, our company is a leading innovator in the development of state-of-the-art trading bots. Based in the United States, we specialize in providing cutting-edge algorithmic solutions that empower traders to execute intelligent and automated trades with unmatched precision and efficiency. Our team of experts, boasting years of experience in finance and technology, is dedicated to ensuring that our clients access the tools necessary to navigate and thrive in today's dynamic markets. As we continue to advance our technologies, we remain committed to maintaining transparency, reliability, and excellence in all aspects of our services.
            </p>
          </div>
        </div>
      </section>




    </>
  )
}
