import React from 'react';
import { Link } from 'react-router-dom';

const StripeReturn = () => {

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full text-center">
        <h1 className="text-4xl font-bold text-green-600 mb-4">Onboarding Complete</h1>
        <p className="text-gray-700 mb-6">
          Your account setup is complete. You can now access your dashboard.
        </p>
        <div className="flex justify-center space-x-4">
          <Link to="/" className="text-blue-500 hover:text-blue-700 transition-all">
            Go to Dashboard
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StripeReturn;
