import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axiosInstance from "../utils/axios";
import { toast } from 'react-toastify';

function Verify() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const [verified, setVerified] = useState(false);

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const response = await axiosInstance.post('/confirm-email', {
                    token
                });
                if (response.status == 200) {
                    setVerified(true);
                }
            }
            catch (error) {
                if (error.response) {
                    toast.error(error.response.data.msg);
                } else {
                    console.error('Error:', error.message);
                }
            }
        };
        if (token) {
            verifyEmail();
        }
    }, [token])
    return (
        <div className="min-h-screen bg-gray-50 flex items-center justify-center">
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="text-center">
                    <div className="mb-4 flex justify-center items-center">
                        {
                            verified ?
                                (
                                    <svg
                                        className="mx-auto h-16 w-16 text-green-400"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M5 13l4 4L19 7"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        className="animate-spin h-16 w-16 text-blue-500"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        />
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8v0a8 8 0 018 8h-2a6 6 0 10-12 0v0H4z"
                                        />
                                    </svg>
                                )
                        }
                    </div>
                    <h2 className="text-2xl font-bold text-gray-800">{verified ? "Email Confirmed!" : "Verifying..."}</h2>
                    <p className="mt-2 text-gray-600">
                        {
                            verified ?
                                "Your email has been successfully confirmed. You can now log in and enjoy our services."
                                : "Verifying your email address..."
                        }
                    </p>
                    {
                        verified &&
                        <div className="mt-6">
                            <a
                                href="/login"
                                className="inline-block bg-green-500 text-white font-bold py-2 px-4 rounded-full hover:bg-green-600 transition duration-300"
                            >
                                Go to Login
                            </a>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Verify;
