import React from 'react';
import Slider from 'react-slick';
import '../assets/css/Slider.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ImageSlider = () => {
    const images = [
        require('../assets/img/carousel-demo.png'),
        require('../assets/img/carousel-demo2.png'),
        require('../assets/img/carousel-demo3.png'),
        require('../assets/img/carousel-demo4.png'),
        require('../assets/img/carousel-demo5.png'),
        require('../assets/img/carousel-demo6.png'),
    ];

    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="slider-main">
            <Slider {...settings}>
                {images.map((src, index) => (
                    <div className="slider-container" key={index}>
                        <img className="slide-img" alt="" src={src} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ImageSlider;