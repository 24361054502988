import React, { useState } from 'react';
import axiosInstance from '../utils/axios';
import { toast } from 'react-toastify';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [waiting, setWaiting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setWaiting(true);
      const response = await axiosInstance.post('/password-reset', { email });
      setWaiting(false);
      toast.success('Password reset email sent successfully!');
    } catch (error) {
      setWaiting(false);
      toast.error('Error sending password reset email. Please try again.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="max-w-md w-full bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold text-center mb-6">Reset Your Password</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
              placeholder="Enter your email"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 bg-blue-500 text-white rounded-md transition hover:bg-blue-600 focus:ring-2 focus:ring-blue-300"
          >
            {waiting ? (
              <svg
                className="animate-spin h-5 w-5 text-white mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v0a8 8 0 018 8h-2a6 6 0 10-12 0v0H4z"
                ></path>
              </svg>
            ) : (
              'Send Reset Link'
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordReset;
