import Header from "../components/Header";
import '../assets/css/styles.css';
import FooterContents from "../components/FooterContents";
import tele_icon from "../assets/img/telegram_icon.png";
import { useState } from "react";
import axiosInstance from "../utils/axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";


function Login() {

  const [formData, setFormData] = useState({
      username: '',
      password: '',
  });
  const [errors, setErrors] = useState({});
  
  const [waiting, setWaiting] = useState(false);

  const handleChange = (e) => {
      setFormData({
          ...formData,
          [e.target.name]: e.target.value,
      });
      setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      const tempErrors = {};
      
      // Basic validation
      if (!formData.username.includes('@') && formData.username.length < 5) {
        tempErrors.username = 'Please enter a valid email or username with at least 5 characters.';
      }
      if (formData.password.length < 6) {
        tempErrors.password = 'Password must be at least 6 characters long.';
      }
  
      setErrors(tempErrors);
  
      if (Object.keys(tempErrors).length > 0) {
        return;
      }
      // Handle form submission and Stripe payment here
      try {
        setWaiting(true);
        const response = await axiosInstance.post('/login', {
            ...formData,
          }, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            withCredentials: true
        });
        setWaiting(false);
        const result = response.data;
        console.log(result);
        if(result.msg)
            toast.success(result.msg)
        window.location.href = process.env.REACT_APP_BACKEND_URL
      }
      catch(error) {
        setWaiting(false);
        if (error.response) {
          toast.error(error.response.data.msg);
        } else {
          console.error('Error:', error.message);
        }
      }
  };

  const isReadyForSubmit = () => {
    return formData.username.length > 0 && formData.password.length > 0
  }

  return (
    <>
      <div className="main-area relative">
        <div className="tele_icon"><img src={tele_icon} alt="" className="fixed right-3 bottom-3 z-50" /></div>
        <Header />
        <div className="py-16 flex items-center justify-center bg-gray-100">
          <div className="bg-white p-8 rounded shadow-md w-full max-w-lg">
            <h2 className="text-2xl font-bold mb-6 text-center">Login to Your Account</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="username">Username Or Email</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
                {errors.username && <p className="text-red-500 text-sm">{errors.username}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
                {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
              </div>
              <div className="mt-4">
                <button
                  type="submit"
                  disabled={!isReadyForSubmit() || waiting}
                  className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                >
                  { waiting ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v0a8 8 0 018 8h-2a6 6 0 10-12 0v0H4z"
                      ></path>
                    </svg>
                  ) : (
                    'Log In'
                  )}
                </button>
              </div>
            </form>
            <div className="mt-4 text-center">
              <Link to="/password-reset" className="text-blue-500 hover:underline">
                Forgot Password?
              </Link>
            </div>
          </div>
        </div>
        <FooterContents />
      </div>
    </>
  );
}

export default Login;
